import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import config from '../../../../config/site'
import Layout from '../../Templates/Layout'
import Container from '../..//Templates/Container'
import Header from '../..//Templates/Header'
import PostListItem from '../../Organisms/PostListItem'

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.text.base};
  padding: 5px 10px;
  border: solid 1px #fff;
  border-radius: 20px;
  &:hover {
    background: ${props => props.theme.colors.background};
  }
`

const Information = styled.div`
  text-align: center;
  h1 {
    font-size: 2rem;
    margin-bottom: 1.25rem;
  }
`

const Tag = ({ pageContext }) => {
  const { posts, tagName } = pageContext
  const upperTag = tagName.charAt(0).toUpperCase() + tagName.slice(1)
  return (
    <Layout>
      <Helmet title={`${tagName} | ${config.siteTitle}`} />
      <Header title={upperTag}>
        <StyledLink to='/tags'>All Tags</StyledLink>
      </Header>
      <Container>
        <Information>
          {
            posts.map((post, index) => (
              <PostListItem
                key={index}
                title={post.frontmatter.title}
                created_at={post.frontmatter.created_at}
                updated_at={post.frontmatter.updated_at}
                tags={post.frontmatter.tags}
                path={post.frontmatter.path}
                excerpt={post.frontmatter.excerpt}
                cover={post.frontmatter.cover ? post.frontmatter.cover.childImageSharp.fluid : {}}
              />
            ))
          }
        </Information>
      </Container>
    </Layout>
  )
}

export default Tag

Tag.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.array,
    tagName: PropTypes.string
  })
}