module.exports = {
    pathPrefix: '/',
    title: 'Not Be Forgotten',
    titleAlt: 'Not Be Forgotten',
    description: '忘れてはいけないことを整理する。',
    url: 'https://not-be-forgotten.yonayona.info',
    siteUrl: 'https://not-be-forgotten.yonayona.info/',
    siteLanguage: 'ja',
    logo: 'src/images/not-be-forgotten.png',
    banner: 'src/images/not-be-forgotten.png',
    favicon: 'src/images/not-be-forgotten.png',
    shortName: 'Not Be Forgotten',
    author: 'Yona',
    themeColor: '#3e7bf2',
    backgroundColor: '#d3e0ff',
    twitter: 'yona_developer',
    googleAnalyticsID: "UA-92423181-9"
  };